<template>
  <vs-tabs
    v-if="isTabsLoaded && company"
    :value="activeTab"
    :position="isSmallerScreen ? 'top' : 'top'"
    class="tabs-shadow-none"
    :class="{ 'user-settings-tabs--mobile': isMobile, 'user-settings-tabs--mobile-app': isMobileApp }"
    id="profile-tabs"
    :key="tabKey"
  >
    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? $t('vue.general') : ''">
      <div class="tab-general md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-general :isMobile="isMobile" />
      </div>
    </vs-tab>

    <vs-tab
      v-if="userHasProviderIdPassword && !activeUserInfo.hasSetPassword"
      icon-pack="feather"
      icon="icon-lock"
      :label="!isSmallerScreen ? $t('vue.setPassword') : ''"
    >
      <div class="tab-change-pwd md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-set-password />
      </div>
    </vs-tab>

    <vs-tab
      v-if="userHasProviderIdPassword && activeUserInfo.hasSetPassword"
      icon-pack="feather"
      icon="icon-lock"
      :label="!isSmallerScreen ? $t('vue.changePassword') : ''"
    >
      <div class="tab-change-pwd md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-change-password :isMobile="isMobile" />
      </div>
    </vs-tab>

    <!--<vs-tab icon-pack="feather" icon="icon-info" :label="!isSmallerScreen ? $t('vue.info') : ''">
      <div class="tab-info md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-info />
      </div>
    </vs-tab> -->

    <vs-tab v-if="!(!HAS_ACCESS_SCHEDULING && isMobileApp)" icon-pack="feather" icon="icon-clock" :label="!isSmallerScreen ? $t('vue.availability') : ''">
      <div class="tab-info md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-availability :isMobile="isMobile" />
      </div>
    </vs-tab>

    <vs-tab v-if="company" icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? $t('vue.changeEmail') : ''">
      <div class="tab-info md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-update-email :isMobile="isMobile" />
      </div>
    </vs-tab>

    <vs-tab v-if="company" icon-pack="feather" icon="icon-volume-2" :label="!isSmallerScreen ? $t('vue.ringtones') : ''">
      <div class="tab-info md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-ringtone :isMobile="isMobile" />
      </div>
    </vs-tab>

    <!-- <vs-tab icon-pack="feather" icon="icon-message-square" :label="!isSmallerScreen ? $t('vue.notifications') : ''">
      <div class="tab-info md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-notification />
      </div>
    </vs-tab> -->
    <vs-tab
      v-if="isMobileApp || (!isMobile && NODE_ENV === 'pathadvice-stage')"
      icon-pack="feather"
      icon="icon-user-x"
      :label="!isSmallerScreen ? $t('vue.deleteAccount') : ''"
    >
      <div class="tab-info md:mt-0 ml-0" :class="{ 'mt-4': !isMobile, 'mt-2': isMobile }">
        <user-settings-delete-account />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters } from 'vuex'

import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'
import UserSettingsSetPassword from './UserSettingsSetPassword.vue'
// import UserSettingsInfo from './UserSettingsInfo.vue'
import UserSettingsAvailability from './UserSettingsAvailability.vue'
//import UserSettingsNotification from './UserSettingsNotification.vue'
import UserSettingsDeleteAccount from './UserSettingsDeleteAccount.vue'
import UserSettingsUpdateEmail from './UserSettingsUpdateEmail.vue'
import UserSettingsRingtone from './UserSettingsRingtone.vue'

import { mobile_check } from '@/mixins/mobile_check'

import { Device } from '@capacitor/device'

export default {
  components: {
    UserSettingsGeneral,
    UserSettingsChangePassword,
    UserSettingsSetPassword,
    // UserSettingsInfo,
    UserSettingsAvailability,
    // UserSettingsNotification,
    UserSettingsDeleteAccount,
    UserSettingsUpdateEmail,
    UserSettingsRingtone
  },
  mixins: [mobile_check],
  data() {
    return {
      key: Math.random().toString(36).substring(2, 15),
      isMobile: false,
      isMobileApp: false,
      isTabsLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'company'
    }),
    currentUser() {
      return this.$firebase.auth().currentUser
    },
    NODE_ENV() {
      return process.env.NODE_ENV
    },
    userHasProviderIdPassword() {
      let _userHasProviderIdPassword = false
      const providerData = this.currentUser.providerData
      if (providerData) {
        const passwordPD = providerData.find((x) => x.providerId === 'password')
        if (passwordPD) {
          _userHasProviderIdPassword = true
        }
      }

      return _userHasProviderIdPassword
    },
    tabKey() {
      return this.$store.state.windowWidth < 768 || this.key
    },
    isSmallerScreen() {
      return this.isMobile
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    activeTab() {
      const setPasswordActiveTab =
        typeof this.activeUserInfo.hasSetPassword !== 'undefined' && this.activeUserInfo.hasSetPassword === false && !this.activeUserInfo.provider

      if (setPasswordActiveTab) {
        return 1
      } else if (
        (this.$route.params && this.$route.params.from === 'verify-sync-calendar') ||
        (this.$route.query && this.$route.query.tab === 'availability')
      ) {
        return 2
      }

      return 0
    },
    HAS_ACCESS_SCHEDULING() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('scheduling') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    }
  },
  async created() {
    this.isMobile = this.mobileCheck()
    await this.checkIsMobileApp()

    this.$serverBus.$on('route-title-update', () => {
      this.key = Math.random().toString(36).substring(2, 15)
    })

    await this.setSyncedApiCredentials()

    this.isTabsLoaded = true
  },

  beforeDestroy() {
    this.$serverBus.$off('route-title-update')
  },

  methods: {
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
          this.key = Math.random().toString(36).substring(2, 15)
        }
      } catch (err) {
        /* eslint-disable-next-line */
        console.log(err)
      }
    },

    async setSyncedApiCredentials() {
      const companyRef = await this.$db.collection('users').doc(this.activeUserInfo.uid).get()
      const user = companyRef.data()

      if (!(this.activeUserInfo.isSyncedCalendar && user.isSyncedCalendar && this.activeUserInfo.syncedCalendarType === user.syncedCalendarType)) {
        await this.$store.commit('UPDATE_USER_INFO', {
          isSyncedCalendar: user.isSyncedCalendar,
          syncedCalendarType: user.syncedCalendarType,
          syncedEmail: user.syncedEmail,
          syncedCalendarsList: user.syncedCalendarsList,
          syncedCalendarId: user.syncedCalendarId,
          syncedCalendarOnlineMeetingToolId: user.syncedCalendarOnlineMeetingToolId
        })
      }
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .con-ul-tabs {
    margin-bottom: 10px;
  }
  .vs-tabs--content {
    padding: 0;
  }
  .pa-bk-secondary {
    background: rgba(var(--vs-secondary), 1) !important;
    border-radius: 10px;
    color: #fff !important;
  }
}

.user-settings-tabs {
  &--mobile {
    .vs-tabs {
      &--ul {
        justify-content: space-between;
        padding: 0 4px;
      }

      &--li {
        width: calc(100% / 4 - 8px);

        .vs-icon {
          font-size: 24px;
        }
      }

      &--btn {
        justify-content: center;
      }
    }

    .user-settings {
      &__title-inner {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: rgb(38, 38, 41);
      }
    }

    &-app {
      .vs-tabs {
        &--li {
          width: calc(100% / 5 - 8px) !important;
        }
      }
    }
  }
}
</style>
