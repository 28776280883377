var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTabsLoaded && _vm.company
    ? _c(
        "vs-tabs",
        {
          key: _vm.tabKey,
          staticClass: "tabs-shadow-none",
          class: {
            "user-settings-tabs--mobile": _vm.isMobile,
            "user-settings-tabs--mobile-app": _vm.isMobileApp,
          },
          attrs: {
            value: _vm.activeTab,
            position: _vm.isSmallerScreen ? "top" : "top",
            id: "profile-tabs",
          },
        },
        [
          _c(
            "vs-tab",
            {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-user",
                label: !_vm.isSmallerScreen ? _vm.$t("vue.general") : "",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tab-general md:mt-0 ml-0",
                  class: { "mt-4": !_vm.isMobile, "mt-2": _vm.isMobile },
                },
                [
                  _c("user-settings-general", {
                    attrs: { isMobile: _vm.isMobile },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.userHasProviderIdPassword && !_vm.activeUserInfo.hasSetPassword
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-lock",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("vue.setPassword")
                      : "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-change-pwd md:mt-0 ml-0",
                      class: { "mt-4": !_vm.isMobile, "mt-2": _vm.isMobile },
                    },
                    [_c("user-settings-set-password")],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.userHasProviderIdPassword && _vm.activeUserInfo.hasSetPassword
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-lock",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("vue.changePassword")
                      : "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-change-pwd md:mt-0 ml-0",
                      class: { "mt-4": !_vm.isMobile, "mt-2": _vm.isMobile },
                    },
                    [
                      _c("user-settings-change-password", {
                        attrs: { isMobile: _vm.isMobile },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !(!_vm.HAS_ACCESS_SCHEDULING && _vm.isMobileApp)
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-clock",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("vue.availability")
                      : "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-info md:mt-0 ml-0",
                      class: { "mt-4": !_vm.isMobile, "mt-2": _vm.isMobile },
                    },
                    [
                      _c("user-settings-availability", {
                        attrs: { isMobile: _vm.isMobile },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.company
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-mail",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("vue.changeEmail")
                      : "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-info md:mt-0 ml-0",
                      class: { "mt-4": !_vm.isMobile, "mt-2": _vm.isMobile },
                    },
                    [
                      _c("user-settings-update-email", {
                        attrs: { isMobile: _vm.isMobile },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.company
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-volume-2",
                    label: !_vm.isSmallerScreen ? _vm.$t("vue.ringtones") : "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-info md:mt-0 ml-0",
                      class: { "mt-4": !_vm.isMobile, "mt-2": _vm.isMobile },
                    },
                    [
                      _c("user-settings-ringtone", {
                        attrs: { isMobile: _vm.isMobile },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isMobileApp ||
          (!_vm.isMobile && _vm.NODE_ENV === "pathadvice-stage")
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-user-x",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("vue.deleteAccount")
                      : "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-info md:mt-0 ml-0",
                      class: { "mt-4": !_vm.isMobile, "mt-2": _vm.isMobile },
                    },
                    [_c("user-settings-delete-account")],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }